.strike-protocols {
  display: block;
  margin: auto;
  @media screen and (max-width: 812px) {
    padding-left: 0;
    padding-right: 12px;
    display: block;
  }
  .strike-protocol-content {
    margin: 130px auto 0 auto;
    max-width: 1000px;

    @media screen and (max-width: 812px) {
    }
    .img-dev {
      .img-app-ui {
        width: 100%;
      }
      .img-app-ui-mobile {
        display: none;
      }
      @media screen and (max-width: 540px) {
        .img-app-ui {
          display: none;
        }

        .img-app-ui-mobile {
          margin: 0 auto;
          display: block;
        }
      }
    }
    .content {
      padding: 60px 60px 60px 50px;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 812px) {
        display: block;
      }

      .text-layer {
        @media screen and (max-width: 812px) {
          width: 100%;
        }
        span:first-child {
          font-size: 48px;
          font-weight: 700;
          color: var(--color-text-main);
          padding-bottom: 30px;
          display: block;
          line-height: 50px;

          @media screen and (max-width: 1440px) {
            font-size: 38px;
            line-height: 40px;
          }
  
          @media screen and (max-width: 1280px) {
            font-size: 38px;
          }
  
          @media screen and (max-width: 1024px) {
            font-size: 26px;
          }
  
          @media screen and (max-width: 812px) {
            font-size: 25px;
          }
        }

        span:nth-child(2) {
          font-size: 18px;
          font-weight: 400;
          color: #aaaaaa;
          display: block;
          padding-bottom: 40px;
          max-width: 544px;
          
          @media screen and (max-width: 1024px) {
            font-size: 16px;
          }

          @media screen and (max-width: 812px) {
            font-size: 16px;
            max-width: 100%;
          }
        }
      }
      .btn-launchapp {
        cursor: pointer;
        width: 169px;
        padding: 12px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid var(--color-text-main);

        span {
          display: block;
          color: var(--color-text-main);
          font-size: 17px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px;
        }
      }
    }
  }
}
