.faq {
  .faq-wrapper {
    position: relative;
    .faq-content {
      padding: 120px 100px 100px 100px;
      @media screen and (max-width: 812px) {
        padding: 65px 38px;
      }
      max-width: 1150px;
      margin-left: auto;
      margin-right: auto;
      .faq-wrapper {
        padding: 15px 25px;
        border: 1px solid #aaa;
        background: #1e1a22;
        .faq-wrapper-question {
          position: relative;
          .question {
            color: #fff;
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            @media screen and (max-width: 812px) {
              font-size: 18px;
            }
          }
          button {
            background: unset;
            border: 0;
            cursor: pointer;
            position: static;
            @media screen and (max-width: 812px) {
              position: absolute;
              right: -20px;
              top: 0;
            }
          }
          button:hover {
            background: unset !important;
          }
        }
        .ans {
          transition: all 300ms ease-in-out;
          color: #aaa;
          font-size: 18px;
          line-height: 40px;
          @media screen and (max-width: 812px) {
            font-size: 14px;
          }
        }
        .open {
          max-height: 500px;
          opacity: 1;
          margin-top: 10px;
        }
        .collapse {
          max-height: 0;
          opacity: 0;
        }
      }
      .faq-wrapper:not(:last-child) {
        margin-bottom: 35px;
      }
    }
  }
}
