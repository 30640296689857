.wrapper {
  max-width: 200px;
  height: 160px;
  width: 100%;
  @media (max-width: 812px) {
    max-width: 100px;
    height: 88px;
  }
  .sanity-image {
    max-width: 100%;
    height: 100% !important;
    object-fit: cover;
  }
}
.detail {
  margin-left: 26px;
  .description {
    color: var(--color-white);
    font-size: 24px;
    font-weight: bold;
    line-height: 34px;
    margin-top: 8px;
    @media (max-width: 812px) {
      font-size: 20px;
      line-height: 20px;
      letter-spacing: -1px;
    }
  }
  @media (max-width: 812px) {
    margin-left: 16px;
  }
}
