.wrap-blogs-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 197px 20px 0px;
  @media (max-width: 1024px) {
    padding: 150px 20px 0px;
  }
  @media (max-width: 812px) {
    padding: 97px 40px 0px !important;
  }
  .bread-crumb {
    color: var(--color-text-gray);
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: -1px;
  }
  .heading-descriptions {
    color: var(--color-white);
    font-size: 48px;
    font-weight: bold;
    line-height: 86px;
    letter-spacing: -1.92px;
    @media (max-width: 1024px) {
      font-size: 42px;
      letter-spacing: -1.68px;
    }
  }
  .heading-descriptions:first-child {
    margin-top: 18px;
  }
  .new-blogs {
    margin-top: 100px;
    @media (max-width: 1024px) {
      padding-top: 60px;
      margin-top: 60px;
      border-top: 2px solid white;
    }
    .heading-descriptions {
      margin-top: 24px;
      line-height: 86px;
      @media (max-width: 1024px) {
        margin-top: 0px;
        line-height: 42px;
      }
    }
    .description {
      color: var(--color-text-gray);
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: -0.5px;
    }
    .new-blogs-grid {
      margin-top: 60px;
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      row-gap: 50px;
      column-gap: 28px;
      @media (max-width: 1024px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        row-gap: 0px;
      }
    }
    .blogs-card-single {
      position: relative;
    }
    button {
      padding: 12px 24px;
      position: relative;
      border: 1px solid var(--color-white);
      background: #1e1a22;
      color: var(--color-white);
      font-size: 18px;
      font-weight: 700;
      line-height: 36px;
      max-width: 169px;
      margin-top: 50px;
      @media (max-width: 812px) {
        margin-top: 24px;
      }
      span {
        margin-right: 19px;
      }
      img {
        position: absolute;
        right: 12px;
      }
    }
    button:hover {
      background: unset !important;
    }
  }
}

.pagination {
  text-align: right;
  margin-top: 20px;
  @media screen and (max-width: 812px) {
    margin-top: 42px;
  }
  .ant-pagination-item-link {
    background: none !important;

    .ant-pagination-item-container {
      border: none;
    }

    i.anticon.anticon-left {
      color: var(--color-text-main) !important;
    }
    i.anticon.anticon-right {
      color: var(--color-text-main) !important;
    }
    .ant-pagination-item-ellipsis {
      color: var(--color-text-main) !important;
    }
  }
  .ant-pagination-item a {
    color: #666;
  }
  .ant-pagination-item {
    background: none !important;
  }
  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a {
    color: var(--color-text-main);
  }

  .ant-pagination-item-active {
    a {
      color: var(--color-text-main);
    }
  }
}