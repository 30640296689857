.sale-card {
  width: 100%;
  height: 100%;
  min-width: 560px;

  @media screen and (max-width: 580px) {
    min-width: unset;
    width: 100%;
  }

  .not-connect-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 680px;
    width: 100%;
    padding: 20px 0px;
    border: 1px solid #AAA;
    background: #1E1A22;

    .logo {
      padding: 100px 0px;
    }

    .title {
      text-align: center;
      max-width: 320px;
      font-size: 24px;
      font-weight: 700;
      color: white;

      .text-highlight {
        color: #107def;
      }

      @media screen and (max-width: 1440px) {
        .text-highlight {
        }
      }

      @media screen and (max-width: 1200px) {
        .text-highlight {
        }
      }

      @media screen and (max-width: 1024px) {
        .text-highlight {
        }
      }

      @media screen and (max-width: 812px) {
        .text-highlight {
        }
      }

      @media screen and (max-width: 480px) {
        .text-highlight {
        }
      }
    }
    
    .connect-btn {
      margin-top: 10px;
      width: 80%;
    }

    .history-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      gap: 10px;
      width: 80%;
      cursor: pointer;
      color: white;
      padding: 12px 24px;
      border: 1px solid white;
      background: #1E1A22;
      font-size: 18px;
    }

    @media screen and (max-width: 768px) {
      min-height: unset;
    }

    @media screen and (max-width: 600px) {
      min-height: unset;
      .logo {
        width: 50%;
        padding: 100px 0px;
      }
    }

  }

  .connect-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: white;
    max-width: 580px;


    .vesting-plan-card {
      padding: 24px 24px;
      border: 1px solid #AAA;
      background: #1E1A22;

      .title {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
      }

      .vesting-plan {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        text-align: left;

        .card {
          padding: 12px 6px;
          border: 1px solid #34384C;
          background: #1E1A22;
          font-size: 14px;
          font-weight: 700;
          cursor: pointer;

          .text-highlight {
            color: #107DEF;
          }

          &:hover {
            border: 1px solid #AAA;
            background: rgba(93, 101, 136, 0.40);
            transition: all 0.5s;
          }
        }

        .card.active {
          border: 1px solid #AAA;
          background: rgba(93, 101, 136, 0.40);
        }
      }
      
    }

    .buy-card {
      padding: 24px 24px;
      border: 1px solid #AAA;
      background: #1E1A22;
      display: flex;
      flex-direction: column;
      gap: 16px;
      font-size: 16px;;

      .buy-option {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .card {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          padding: 12px 20px;
          border: 1px solid #34384C;
          background: #1E1A22;
          color: #34384C;
          font-size: 17px;
          cursor: pointer;

          svg  path {
            fill: #34384C;
          }

          &:hover {
            color: white;
            border: 1px solid #AAA;          
            background: rgba(93, 101, 136, 0.40);
            transition: all 0.5s;
            
            svg  path {
              fill: white;
              transition: all 0.5s;
            }
          }
        }

        .card.active {
          color: white;
          border: 1px solid #AAA;          
          background: rgba(93, 101, 136, 0.40);

          svg  path {
            fill: white;
          }
        }
      }

      .network-option {
        display: flex;
        align-items: center;
        gap: 10px;

        .network {
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          padding: 10px 20px;
          border: 1px solid #34384C;
  
          font-size: 20px;
          font-weight: 700;
  
          img {
            width: 24px;
            height: 24px;
          }

          &:hover {
            border: 1px solid #AAA;          
            background: rgba(93, 101, 136, 0.40);
          }
        }

        .network.active {
          border: 1px solid #AAA;          
          background: rgba(93, 101, 136, 0.40);
        }
      }

      .asset-balance, .available-strk {
        user-select: none;
        cursor: pointer;
      }

      .input-option {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;

        .input-card {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 12px 11px;
          border: 1px solid #34384C;

          input {
            display: flex;
            width: 100%;
            font-size: 30px;
            background: transparent;
            outline: none;
            border: 1px solid #34384C;
            padding: 0px 12px;
          }

        }

        .input-card.active {
          border: 1px solid #AAA;
        }
      }

      .limit-info .red {
        color: red;
      }

      .asset-option {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
      }

      .asset {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        font-size: 20px;
        font-weight: 700;

        img {
          width: 24px;
          height: 24px;
        }
      }

      .action-option {
        display: grid;
        grid-template-columns: 180px 1fr;
        gap: 10px;

        font-size: 18px;
        font-weight: 700;

        .buy-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #AAA;
          background: #34384C;
        }

        .buy-btn.enable {
          color: white;
          background: #107DEF;
          cursor: pointer;
        }

        .connect-btn {
          width: 100%;
        }

        .history-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          width: 100%;
          cursor: pointer;
          color: white;
          padding: 12px 24px;
          border: 1px solid white;
          background: #1E1A22;
        }
      }
    }
    
    @media screen and (max-width: 520px) {
      .vesting-plan-card {
        padding: 16px 16px;
  
        .title {
          font-size: 20px;
        }
  
        .vesting-plan {
          margin-top: 20px;
  
          .card {
            font-size: 12px;
          }
        }
        
      }
  
      .buy-card {
        padding: 16px 16px;
        font-size: 14px;;
  
        .buy-option {
          .card {
            padding: 12px 20px;
            font-size: 14px;
          }
        }
  
        .network-option {
          .network {
            padding: 8px 16px;
            font-size: 14px;
   
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
  
        .input-option {
          .input-card {
            padding: 10px 10px;
            input {
              font-size: 20px;
              padding: 0px 8px;
            }
          }
        }
  
        .asset {
          font-size: 14px;
  
          img {
            width: 20px;
            height: 20px;
          }
        }
  
        .action-option {
          grid-template-columns: 100px 1fr;

          font-size: 14px;
          font-weight: 700;

          .history-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            cursor: pointer;
            color: white;
            padding: 12px 16px;
            border: 1px solid white;
            background: #1E1A22;
          }
        }
      }      
    }

    @media screen and (max-width: 500px) {
      .asset-option {
        .label {
          display: none;
        }
      }
    }
  }
}