.sale-homepage {
  // max-height: 1000px;
  padding-top: 86px;
  // padding-bottom: 58px;

  .christmas-banner {
    width: 100%;
    margin-top: -30px;
  }

  @media screen and (max-width: 1960px) {
    min-height: 800px;
  }

  @media screen and (max-width: 1200px) {
    max-height: unset;
  }

  @media screen and (max-width: 768px) {
    min-height: 100vh;
    .christmas-banner {
      width: 100%;
      margin-top: unset;
    }
  }

  .sale-content-wrapper {
    padding: 70px 120px 0px;   

    @media screen and (max-width: 1400px) {
      padding: 70px 50px 0px;
    }

    @media screen and (max-width: 812px) {
      padding: 50px 20px 0px;      
    }
  }
  
  .sale-content {
    display: flex;
    justify-content: space-between;
    z-index: 50;

    @media screen and (max-width: 1400px) {
    }

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      gap: 50px;
    }

    @media screen and (max-width: 812px) {
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 550px;
      max-height: 720px;
      position: relative;

      .santa-hat {
        width: 120px;
        position: absolute;
        left: -85px;
        top: -80px;
      }

      
      @media screen and (max-width: 1400px) {
        .santa-hat {
          left: -65px;
        }
      }

      @media screen and (max-width: 768px) {
        max-height: unset;
        .santa-hat {
          width: 80px;
          left: -30px;
          top: -50px;
        }
      }

      .title {
        font-size: 20px;
        font-weight: 400;
        color: var(--color-text-main);
      }
  
      .sub-title {
        padding: 10px 0px;
        font-size: 26px;
        font-weight: 700;
  
        .text-highlight {
          color: #107def;
        }
  
        @media screen and (max-width: 1440px) {
        }
  
        @media screen and (max-width: 1200px) {
        }
  
        @media screen and (max-width: 1024px) {
        }
  
        @media screen and (max-width: 812px) {
        }
  
        @media screen and (max-width: 480px) {
        }
      }
  
      .description {
        color: #aaaaaa;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        max-width: 550px;
      }

      .register-link {
        color: white;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        max-width: 550px;
        margin-top: 10px;
      }

      .status {
        margin-top: 30px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 20px;
        color: white;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }

        .dot.Coming {
          background: #e25429;
        }
        .dot.Open {
          background: #57e727;
        }
        .dot.Done {
          background: #107def;
        }
      }

      .countdown {
        display: flex;
        justify-content: space-between;

        .pad {
          color: #AAA;

          .value {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 95px;
            height: 95px;
            border: 1px solid #D9D9D9;
            font-size: 62px;
            line-height: 38px;
            font-family: 'Euclid';
          }

          .label {
            margin-top: 12px;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
          }

          @media screen and (max-width: 480px) {
            .value {
              width: 75px;
              height: 75px;
              font-size: 40px;
            }
  
          }
        }
      }

      .price-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        margin-top: 54px;

        .label {
          color: #F6851B;
        }

        .price {
          background: rgba(255, 255, 255, 0.10);
          padding: 8px;
          line-height: 16px;
          color: #AAA;

          .high-light {
            color: white;
          }
        }

        @media screen and (max-width: 480px) {
          font-size: 14px;
        }
      }

      .progress-bar {
        margin-top: 18px;
        .ant-progress-inner {
          background-color: rgba(170, 170, 170, 0.40);
          border-radius: unset;
        }

        .ant-progress-bg {
          border-radius: unset;
        }
      }

      .round-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        margin-top: 18px;

        .sale-amount {
          color: #107DEF;
          width: 135px;
        }

        .round {
          color: #AAA;
        }

        .raise-amount {
          color: #107DEF;
          width: 135px;
          text-align: right;
        }

        @media screen and (max-width: 480px) {
          font-size: 12px;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: end;
      gap: 50px;
      min-height: 660px;
      text-align: left;

      @media screen and (max-width: 580px) {
        width: 100%;
      }
    }
  }
}