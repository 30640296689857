.developer-docs {
  padding: 0px 120px;
  display: block;
  margin: auto;
  
  @media screen and (max-width: 1280px) {
    padding: 0px;
    padding-left: 120px;
  }

  @media screen and (max-width: 812px) {
    display: block;
    padding: 0px 20px;
  }
  .developer-docs-content {

    @media screen and (max-width: 812px) {
      flex-direction: column !important;
      display: block;
    }
    .content {
      padding: 260px 0px;
      max-width: 600px;

      @media screen and (max-width: 1280px) {
        padding: 200px 0px;
      }

      @media screen and (max-width: 1024px) {
        padding: 150px 0px;
      }

      @media screen and (max-width: 812px) {
        padding: 0px;
        padding-top: 100px;
      }

      .text-developers {
        font-size: 25px;
        font-weight: 500;
        color: #107def;
        display: block;
        padding-bottom: 10px;
        @media screen and (max-width: 812px) {
          display: block;
        }
      }

      .text-layer-1 {
        padding-bottom: 30px;
        flex-basis: min-content;
        max-width: 74%;
        font-size: 48px;
        font-weight: 500;
        color: var(--color-text-main);
        line-height: 50px;
        display: block;
        max-width: 100%;
        @media screen and (max-width: 1440px) {
          width: 90%;
          font-size: 38px;
          line-height: 40px;
        }

        @media screen and (max-width: 1280px) {
          font-size: 38px;
        }

        @media screen and (max-width: 1024px) {
          font-size: 26px;
        }

        @media screen and (max-width: 812px) {
          font-size: 25px;
          width: 100%;
        }
      }
      .text-layer-2 {
        padding-bottom: 40px;
        @media screen and (max-width: 812px) {
          padding-right: 0;
        }
        .strike-protocol {
          font-size: 18px;
          font-weight: 500;
          color: #aaaaaa;
          display: block;
          max-width: 77%;
          @media screen and (max-width: 812px) {
            display: block;
            max-width: 100%;
            font-size: 16px;
          }
          @media screen and (max-width: 1024px) {
            display: block;
            max-width: 100%;
          }
        }
      }
      .button {
        cursor: pointer;
        width: 169px;
        padding: 12px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid var(--color-text-main);

        @media screen and (max-width: 812px) {
        }

        .button-dev {
          display: block;
          color: var(--color-text-main);
          font-size: 17px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px;
          
          @media screen and (max-width: 812px) {
          }
        }
      }
    }

    .img-dev {
      display: flex;
      height: 100%;
      align-items: center;
      margin: auto 0;
      width: 483px;

      @media screen and (max-width: 1280px) {
        width: 400px;
      }

      @media screen and (max-width: 1024px) {
        margin: auto;
        min-width: 380px;
      }

      @media screen and (max-width: 812px) {
        text-align: center;
        margin: 0 auto;
        width: 300px;
      }

      .img-blockchain {
        width: 100%;
        @media screen and (max-width: 812px) {
          margin: auto;
          text-align: center;
        }
      }
    }
  }
}
