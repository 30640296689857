:root {
    /* Common styles */
    --color-white: #FFFFFF;
    --color-black: #000000;
    --color-orange: #ff4a21;
    --color-yellow: #f8b94b;
    --color-purple: #906DE5;
    --color-red: #f9053e;
    --color-green: #9dd562;
    --color-blue: #107def;
    --color-dark-grey: #666771;
    /* Background colors */
    --color-bg-main: #1e1a22;
    --color-bg-primary: #ffffff;
    --color-bg-active: #f8f9fa;
    /* Typography colors */
    --color-text-main: #ffffff;
    --color-text-secondary: #c5cbd4;
    --color-text-inactive: #5e5f6a;
    --color-text-green: #97cc60;
    --color-text-blue: #2fe29b;
    --color-text-black: #0B0F23;
    --color-text-gray: #aaaaaa;
    --color-text-dark-gray: #808D9E;

    /* Chart color */
    --color-chart-supply: #00D395;
    --color-chart-borrow: #9669ED;
}

@font-face {
    font-family: "DMSans";
    src: url("../font/DMSans/DMSans-Regular.ttf");
    font-weight: normal;
}

@font-face {
    font-family: "DMSans";
    src: url("../font/DMSans/DMSans-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "DMSans";
    src: url("../font/DMSans/DMSans-Bold.ttf");
    font-weight: 700;
}

@font-face {
    font-family: "Euclid";
    src: url("../font/Euclid/Euclid-Circular-A-Regular.ttf");
    font-weight: normal;
}

@font-face {
    font-family: "Euclid";
    src: url("../font/Euclid/Euclid-Circular-A-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Euclid";
    src: url("../font/Euclid/Euclid-Circular-A-Bold.ttf");
    font-weight: 700;
}

body * {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-family: "DMSans";
    font-stretch: normal;
    font-style: normal;
}

a, span, p, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0px;
    font-family: "DMSans";
}

.input-error {
    color: var(--color-red);
    font-size: 18px;
}
