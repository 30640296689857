.market-homepage {
    // min-height: 1000px;
    padding: 0 120px;

    @media screen and (max-width: 812px) {
        padding: 10px 20px;
    }
    
    @media screen and (max-width: 812px) {
        padding: 10px 20px;
    }
}

.slider-animation {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    animation: marquee 25s linear infinite;
    border-top: 1px solid #D1D1D1;
    border-bottom: 1px solid #D1D1D1;

    @keyframes marquee {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(-50%, 0, 0);
        }
    }
}

.slider {
    display: inline-block;
    font-size: 60px;
    line-height: 84px;
    font-weight: 700;
    color: #464646;

    @media screen and (max-width: 812px) {
        font-size: 37.5px;
        line-height: 52.5px;
    }
}

.slider-text {
    margin-right: 250px;
}

.loading-spinner {
    height: fit-content;
    width: 100%;
    background: var(--color-bg-main);

    @media only screen and (max-width: 1440px) {
        height: 70vh;
    }
}