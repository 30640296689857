.partners {
  padding: 0px 120px;
  display: block;
  margin: auto;
  @media screen and (max-width: 1023px) {
    padding: 0px 20px;
  }

  .partner-content {
    padding: 118px 0px;

    @media screen and (max-width: 767px) {
      padding: 100px 0px;
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap:20px;
      margin: auto;
      max-width: 1441px;
      padding: 0px 50px;
      @media screen and (max-width: 1280px) {
        padding: 0px 50px;
      }

      @media screen and (max-width: 1024px) {
        padding: 0px 50px;
      }

      @media screen and (max-width: 812px) {
        padding: 0px 50px;
      }

      .img-partner {
        display: flex;
        width: 275px;
        height: 175px;
        justify-content: center;
        align-items: center;
        border: 1px solid #37323D;
        background: rgba(30, 26, 34, 1);

        &:hover {
          background: #37323D;          
        }
  
        @media screen and (max-width: 1200px) {
          
        }
  
        @media screen and (max-width: 992px) {
          
        }
  
        img {
          max-width: 100%;
          @media screen and (max-width: 812px) {
            margin: auto;
          }
        }
      }
    }    
  }
}
