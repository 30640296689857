.strike-whitepaper {
  padding-left: 120px;
  display: block;
  margin: auto;
  padding-bottom: 215px;
  @media screen and (max-width: 1023px) {
    padding: 20px;
    padding-bottom: 120px;
  }

  .strike-whitepaper-content {
    display: flex;
    justify-content: space-between;
    padding-top: 180px;
    gap: 100px;

    @media screen and (max-width: 1280px) {
      gap: 50px;
    }

    @media screen and (max-width: 1023px) {
      flex-direction:column-reverse
    }

    @media screen and (max-width: 768px) {
      padding-top: 80px;
    }

    .content {
      @media screen and (max-width: 1024px) {
        max-width: 500px;
      }

      .text-layer {
        width: 100%;
        span:first-child {
          font-size: 48px;
          font-weight: 700;
          color: var(--color-text-main);
          line-height: 50px;
          display: block;
          @media screen and (max-width: 1440px) {
            font-size: 38px;
            line-height: 40px;
          }
  
          @media screen and (max-width: 1280px) {
            font-size: 38px;
          }
  
          @media screen and (max-width: 1024px) {
            font-size: 26px;
          }
  
          @media screen and (max-width: 812px) {
            font-size: 25px;
          }
        }

        span:nth-child(2) {
          font-size: 18px;
          font-weight: 400;
          color: #aaaaaa;
          display: block;
          padding-top: 30px;
          max-width: 75%;
          line-height: 30px;
          @media screen and (max-width: 812px) {
            max-width: 100%;
            font-size: 16px;
          }

          @media screen and (max-width: 1024px) {
            max-width: 99%!important;
          }
        }
      }
      .btn-whitepaper {
        width: 169px;
        padding: 12px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid var(--color-text-main);
        margin-top: 24px;
        cursor: pointer;

        span {
          display: block;
          color: var(--color-text-main);
          font-size: 17px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px;
        }
      }
    }
    .img-dev {
      width: 100%;
      text-align: center;
      margin: 0 auto;

      @media screen and (max-width: 1200px) {
        
      }

      @media screen and (max-width: 992px) {
        
      }

      img {
        max-width: 100%;
        @media screen and (max-width: 812px) {
          margin: auto;
        }
      }
    }
  }
}
