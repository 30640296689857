.total-borrows {
  margin-top: 88px;
  @media screen and (max-width: 812px) {
    margin-top: 50px;
  }
  .total-borrow-content {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 812px) {
      flex-direction: column;
    }
    &__left {
      @media screen and (max-width: 812px) {
        padding-bottom: 50px;
        width: 100%;
      }
      .title {
        font-size: 25px;
        color: var(--color-text-main);
        font-weight: 500;
        padding-bottom: 10px;
        @media screen and (max-width: 812px) {
          display: block;
          padding-bottom: 10px;
        }
      }
      .title-desciption {
        font-size: 48px;
        font-weight: 700;
        color: var(--color-text-main);
        width: 70%;
        padding-bottom: 24px;
        line-height: 50px;

        @media screen and (max-width: 1440px) {
          width: 90%;
          font-size: 38px;
          line-height: 40px;
        }

        @media screen and (max-width: 1280px) {
          font-size: 38px;
        }

        @media screen and (max-width: 1024px) {
          font-size: 26px;
        }

        @media screen and (max-width: 812px) {
          font-size: 25px;
          width: 100%;
        }        
      }
      .title-strike {
        font-size: 18px;
        font-weight: 400;
        color: #aaaaaa;
        width: 55%;

        @media screen and (max-width: 1440px) {
          width: 80%;
        }

        @media screen and (max-width: 1280px) {
          width: 90%;
        }

        @media screen and (max-width: 1024px) {
          width: 90%;
          font-size: 18px;
        }

        @media screen and (max-width: 812px) {
          font-size: 14px;
          width: 100%;
        }
        
      }
    }
    &__right {
      background: #1E1F25;
      border-left: 4px solid rgba(16, 125, 239, 0.50);
      border-right: 4px solid rgba(16, 125, 239, 0.50);
      border-bottom: 4px solid rgba(16, 125, 239, 0.50);
      padding: 25px 40px 27px 40px;
      min-width: 500px;

      @media screen and (max-width: 1280px) {
        min-width: 400px;
      }

      @media screen and (max-width: 960px) {
        min-width: 350px;
      }

      @media screen and (max-width: 812px) {
        width: 100%;
        padding-left: 26px;
        padding-right: 26px;
      }
      

      .children-content {
        width: 100%;
        @media screen and (max-width: 812px) {
          width: 100%;
        }

        .title-content {
          font-size: 18px;
          font-weight: 600;
          color: var(--color-text-main);
          margin-bottom: 23px;
          line-height: 120%;

          @media screen and (max-width: 1024px) {
            font-size: 16px;
          }
        }
        .money-borrow {
          font-size: 25px;
          font-weight: 800;
          color: var(--color-blue);
          margin-bottom: 9px;
          text-align: right;
          @media screen and (max-width: 1024px) {
            font-size: 16px;
          }
        }
        .top-markets {
          color: #5D6588;
          font-size: 20px;
          font-weight: 400;
          @media screen and (max-width: 1024px) {
            font-size: 16px;
          }
        }
        .coin-info {
          display: flex;
          justify-content: space-between;
          padding-bottom: 5px;
          padding-top: 21px;
          color: var(--color-text-main);
          .coin-percent {
            font-size: 16px;
            font-weight: 500;
            @media screen and (max-width: 1024px) {
              font-size: 14px;
            }
          }
        }

        .footer-content {
          margin-top: 32px;
          &__left {
            &__title {
              font-size: 13px;
              font-weight: 400;
              color: #5D6588;
              line-height: 21px;
              @media screen and (max-width: 1024px) {
                font-size: 13px !important;
              }
            }
            &__number {
              font-size: 21px;
              color: var(--color-blue);
              font-weight: 400;
              line-height: 120%;
              @media screen and (max-width: 1024px) {
                font-size: 14px !important;
              }
            }
          }
          &__right {
            &__title {
              font-size: 13px;
              font-weight: 400;
              color: #5D6588;
              line-height: 21px;
              @media screen and (max-width: 1024px) {
                font-size: 13px !important;
              }
            }
            &__number {
              font-size: 21px;
              color: var(--color-blue);
              font-weight: 400;
              line-height: 120%;
              @media screen and (max-width: 1024px) {
                font-size: 14px !important;
              }
            }
          }

          .footer-content__right__number {
            text-align: right;
          }
        }
      }
    }
  }
}
