@import 'assets/styles/Global.scss';

body {
    /* width */
    &::-webkit-scrollbar {
        width: 0px;
        height: 5px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: #F6F6F6; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: gray;
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
    background: var(--color-bg-main);
    background-image: url('../../assets/img/grid.png');

    @media screen and (max-width: 768px) {
        background-image: url('../../assets/img/grid-small.png');
    }
    max-width: 1960px;
    margin: 0 auto;
}
.switch-wrapper {
    position: relative;
}

.highlight {
    &:hover { 
        font-weight: bold;
        color: var(--color-blue) !important;
    }
}
// .switch-wrapper > div {
//     position: absolute;
// }

.scrollbar {
    /* width */
    &::-webkit-scrollbar {
        width: 0px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 3px;
        background-color: var(--color-blue);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #e53d52;
    }

    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}

button:disabled,
button[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #D3D3D3 !important;
    background-image: linear-gradient(to right, #D3D3D3, #D3D3D3) !important;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
}

button:hover {
    background-color: #539ef9 !important;
}

// Common CSS
h4 {
    font-size: 44.5px;
    font-weight: 900;
    color: #39496a;
}

h5 {
    font-size: 18.5px;
    font-weight: 900;
    color: #000000;
}

.flex {
    display: flex;
}
.flex-column {
    flex-direction: column;
}
.align-center {
    align-items: center;
}
.just-center {
    justify-content: center;
}
.just-between {
    justify-content: space-between;
}
.just-around {
    justify-content: space-around;
}
.just-end {
    justify-content: flex-end;
}
.center {
    text-align: center;
}
.right {
    text-align: right;
}
.left {
    text-align: left;
}
.pointer {
    cursor: pointer;
}
.full {
    width: 100%;
}
.mx-auto {
    margin: auto 0;
}
.my-auto {
    margin: 0 auto;
}
.mt-1 {
    margin-top: 10px;
}
.mr-1 {
    margin-right: 10px;
}
.cursor-pointer {
    cursor: pointer;
}
.mt-2 {
    margin-top: 20px;
}

.connect-modal {
    .ant-modal-content {
        border-radius: 6px;
        background-color: #1d1f25;
        .ant-modal-body {
            padding: 0;
            border-radius: 6px;
        }
    }
}