h1, h2, h3, h4, h5, h6 {
  color: white!important;
}
.wrap-blogs-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 197px 20px 0px;
  color: white;
  @media (max-width: 1024px) {
    padding: 150px 20px 0px;
  }
  @media (max-width: 812px) {
    padding: 97px 40px 0px !important;
  }
  .blog-details {
    padding-bottom: 100px;
    margin-bottom: 100px;
    border-bottom: 1px solid var(--color-text-gray);
    padding: 0px 100px 10%;
    @media (max-width: 812px) {
      padding: 0;
    }
    .top {
      .author {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .line {
        width: 76px;
        height: 1px;
        background: var(--color-text-gray);
        margin: 0px 15px;
      }
      .author-name {
        color: #fff;
        font-size: 20px;
        font-weight: 500;
      }
      .blog-image-container {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        @media (max-width: 812px) {
          flex-direction: column-reverse;
        }
        .image-wrapper {
          max-width: 100%;
          .sanity-image {
            max-width: 100%;
            height: 100%;
          }
        }
        .first-column {
          margin-right: 15px;
          border: unset !important;
          button {
            cursor: pointer;
            background: unset;
            border: unset !important;
          }
          button:focus {
            outline: none !important;
          }
          button:hover {
            background: unset !important;
          }
          button:not(:first-child) {
            margin-top: 40px;
          }
        }
        .date {
          margin-left: 15px;
          width: 86px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          border-top: 1px solid #fff;
          border-bottom: 1px solid #fff;
          @media (max-width: 812px) {
            flex-direction: row;
            border-top: unset;
            border-bottom: unset;
            width: 100%;
            margin-left: 0px;
            justify-content: flex-end;
            margin-bottom: 8px;
          }
          .day {
            color: #fff;
            font-size: 42px;
            font-style: italic;
            font-weight: bold;
            line-height: 84px;
            @media (max-width: 812px) {
              font-size: 22px;
              line-height: 100%;
            }
          }
          .month {
            color: var(--color-text-gray);
            font-size: 18px;
            width: 56px;
            line-height: 84px;
            border-top: 1px solid var(--color-text-gray);
            border-bottom: 1px solid var(--color-text-gray);
            @media (max-width: 812px) {
              border-top: unset;
              border-bottom: unset;
              font-size: 12px;
              line-height: 100%;
              margin-left: 22px;
              margin-right: 22px;
              border-left: 1px solid var(--color-text-gray);
              border-right: 1px solid var(--color-text-gray);
            }
          }
          .year {
            color: var(--color-text-gray);
            font-size: 18px;
            line-height: 84px;
            @media (max-width: 812px) {
              font-size: 14px;
              line-height: 100%;
            }
          }
        }
      }
    }
    .content-blocks {
      .header {
        padding: 0px 36px;
        @media (max-width: 812px) {
          padding: 0px;
        }
        .head {
          color: #fff;
          font-size: 48px;
          font-weight: bold;
          line-height: 50px;
          margin-top: 54px;
          @media (max-width: 812px) {
            font-size: 32px;
            line-height: 36px;
            margin-top: 20px;
          }
        }
        .author {
          color: var(--color-text-gray);
          font-size: 18px;
          margin-top: 15px;
          font-weight: 400;
          line-height: 30px;
        }
      }
      .content {
        margin-top: 32px;
        p {
          color: #bfbfbf;
          font-size: 22px;
          line-height: 36px;
          margin-bottom: 20px;
          @media (max-width: 812px) {
            font-size: 14px;
            line-height: 24px;
          }
          strong {
            color: white !important;
          }
        }
        ol {
          li {
            color: var(--color-text-gray);
            font-size: 14px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
