.markets-available {
  margin: 0 -120px;
  margin-top: 100px;
  .markets-available-content {
    padding-top: 120px;
    .title {
      text-align: center;
      color: var(--color-text-main);
      font-size: 60px;
      font-weight: 700;
      line-height: 84px;
      margin-bottom: 30px;
      @media screen and (max-width: 812px) {
        font-size: 25px;
        margin-bottom: 20px;
      }
    }
    .markets-table {
      // margin: 0 200px;
      border-radius: 20px;
      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
      max-width: 1000px;
      margin: 0 auto;
      z-index: 2;
      font-size: 16px;
      @media screen and (max-width: 812px) {
        margin: 0 20px;
      }

      .table-market{
        height: 100%;
        @media screen and (max-width: 812px) {
          height: 100%;
        }
      }
      .pagination{
        text-align: right;
        margin-top: 20px;
        @media screen and (max-width: 812px) {
          // margin-top: 45px;
          margin-top: 42px;
        }
      }
      .title-table {
        color: #0b0f23;
        font-size: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e5e5e5;
      }
      .ant-table-body {
        .ant-table-column-title {
          color: #9d9fa7;
          font-size: 16px;
        }
        .ant-table-header-column {
          float: right;
          // text-align: right;
        }
        .ant-table-thead th:first-child span {
          float: left;
        }
        .ant-table-row {
          .total-supply {
            padding: 0px;
            display: block;
            text-align: right;
            .row1 {
              font-size: 18px;
              font-weight: 800;
              color: var(--color-text-black);
              @media screen and (max-width: 812px) {
                font-size: 9px;
              }
            }
            .row2 {
              color: var(--color-green);
              font-size: 18px;
              @media screen and (max-width: 812px) {
                font-size: 8px;
              }
            }
          }
        }
        .symbol {
          .token-type {
            img {
              width: 36px;
              height: 36px;
              @media screen and (max-width: 812px) {
                width: 18px;
                height: 18px;
              }
            }
            @media screen and (max-width: 812px) {
              margin-right: 5px;
            }
          }
          .mx-auto{
            .row1,.row2{
              @media screen and (max-width: 812px) {
                font-size: 9px!important;
              }
            }
          }
        }
        td {
          cursor: pointer;
        }
        @media screen and (max-width: 812px) {
          .ant-table-column-title {
            color: #9d9fa7;
            font-size: 11px;
            word-break: break-word;
          }
          .ant-table-tbody {
            td {
              padding: 16px 0;
            }
          }
          .ant-table-thead {
            th {
              padding: 16px 5px 16px 0;
              text-align: right;
            }
          }
        }
      }
      &::after {
        content: '';
        position: absolute;
        z-index: -1 !important;
        max-width: 1120px;
        height: 500px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 20px;
        top: 68px;
        left: -68px;
        right: -68px;
        margin-left: auto;
        margin-right: auto;
        @media screen and (max-width: 812px) {
          display: none;
        }
      }
      .color-black {
        color: #000000;
        font-size: 18px;
        @media screen and (max-width: 812px) {
          font-size: 10px;
        }
      }
      .text-ellipsis {
        @media screen and (max-width: 812px) {
          width: 90px;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        @media screen and (max-width: 500px) {
          width: 60px;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .sub-value {
        font-size: 16px;
        @media screen and (max-width: 812px) {
          font-size: 10px;
        }
      }
    }
    @media screen and (max-width: 812px) {
      .ant-card-body {
        padding: 12px;
      }
    }
  }

  .markets-slider-area {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 100px 0px;
    position: relative;

    .market-detail {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 600px;
      border-top: 4px solid #2A2F36;
      border-bottom: 4px solid #2A2F36;
      background: linear-gradient(270deg, #1E1F25 40.12%, #1E1F25 43.58%, rgba(30, 31, 37, 0.97) 48.73%, rgba(30, 31, 37, 0.95) 52.14%, rgba(30, 31, 37, 0.93) 75.07%, rgba(30, 31, 37, 0.86) 90.09%, rgba(30, 31, 37, 0.83) 95.16%, rgba(30, 31, 37, 0.55) 98.77%, rgba(30, 31, 37, 0.00) 100%);
      z-index: 100;
      padding: 43px 87px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .market-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 32px;
        color: var(--color-text-main);
        padding-left: 23px;;
      }

      .market-header {
        display: flex;
        align-items: center;
        gap: 15px;
        color: var(--color-text-main);

        .market-name {
          font-size: 37.347px;
          font-weight: 700;
          line-height: 59.755px;
        }

        .market-symbol {
          font-size: 24px;
          font-weight: 700;
          line-height: 30px;
        }

        img {
          width: 115px;
          height: 115px;
        }
      }
      
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .label {
          color: #AAA;
          font-size: 18px;
          font-weight: 500;
          line-height: 32px;
        }

        .value {
          text-align: right;
          font-size: 18px;
          font-weight: 400;
          line-height: 25px;
          color: white;
        }

        .value.yellow {
          color: #FFD03D;
        }

        .value.green {
          color: #4FD000;
        }
      }

      .divider1 {
        border-bottom: 1px solid white;
      }

      .divider2 {
        border-bottom: 1px solid #34384C;
      }
    }
  }
}
