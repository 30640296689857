.banner-homepage {
  max-height: 1000px;
  // padding-top: 86px;
  padding-bottom: 58px;

  @media screen and (max-width: 1960px) {
    min-height: 100vh;
  }

  @media screen and (max-width: 812px) {
    padding-bottom: 60px;
  }

  @media screen and (max-height: 760px) {
    // padding-top: 60px;
    min-height: calc(100vh + 140px);
  }

  .banner-content {
    padding: 50px 0px;
    position: relative;

    @media screen and (max-width: 1960px) {
      height: calc(100vh - 170px);
    }

    @media screen and (max-width: 812px) {
      display: block;
    }

    .left {
      margin: auto 0;
      padding-left: 120px;

      @media screen and (max-width: 812px) {
        padding: 0px 20px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        width: fit-content;
      }

      .load-wraper {
        position: relative;
        display: inline-flex;
        height: 50px;
        width: 400px;
        background-color: #107def;
        overflow: hidden;
        border-radius: 5px;
        margin-bottom: -8px;

        @media screen and (max-width: 1279px) {
          width: 300px;
        }

        @media screen and (max-width: 539px) {
          width: 250px;
        }

        @media screen and (max-width: 413px) {
          width: 200px;
        }
      }
      
      .load-wraper2 {
        position: relative;
        display: inline-flex;
        height: 50px;
        width: 50px;
        background-color: #107def;
        overflow: hidden;
        border-radius: 5px;
        margin-bottom: -8px;
      }

      .activity {
        position: absolute;
        left: -45%;
        height: 50px;
        width: 45%;
        background-image: linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
        background-image: -moz-linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
        background-image: -webkit-linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
        animation: loading 1s infinite;
        z-index: 1;
      }
    }

    .title {
      font-size: 48px;
      font-weight: 700;
      z-index: 2;
      color: var(--color-text-main);
      position: relative;
      max-width: 750px;

      .arrow-down-fill {
        margin-top: 10px;
        margin-left: 50px;
      }
      .text-highlight {
        color: #107def;
        font-weight: 700;
        font-size: 60px;
      }
      .image-opacity {
        position: absolute;
        top: -50px;
        left: 40%;
      }

      @media screen and (max-width: 1440px) {
        max-width: 600px;
        font-size: 38px;
        .text-highlight {
          font-size: 45px;
        }
      }

      @media screen and (max-width: 1200px) {
        font-size: 33px;
        .text-highlight {
          font-size: 40px;
        }
      }

      @media screen and (max-width: 1024px) {
        font-size: 30px;
        .text-highlight {
          font-size: 36px;
        }
      }

      @media screen and (max-width: 812px) {
        font-size: 33px;
        .text-highlight {
          font-size: 40px;
        }
      }

      @media screen and (max-width: 480px) {
        font-size: 28px;
        .text-highlight {
          font-size: 36px;
        }
      }
    }

    .description {
      color: #aaaaaa;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      max-width: 550px;
      margin-top: 24px;
    }

    .btn-mobile {
      display: none;

      @media screen and (max-width: 1098px) {
        display: flex;
        gap: 24px;
        margin-top: 24px;

        .whitepaper-btn {
          width: 140px;
          height: 40px;
          color: #ffffff;
          border: 1px solid #107def;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .launch-app-btn {
          width: 140px;
          height: 40px;
          color: #107def;
          background: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }

    .image-banner {
      z-index: 1;
      min-width: 600px;
      margin: auto;
      text-align: center;
      img {
        width: 100%;
      }
      
      @media screen and (max-width: 1440px) {
        img {
          width: 85%;
        }
      }

      @media screen and (max-width: 1200px) {
        min-width: unset;
        img {
          width: 80%;
        }
      }

      @media screen and (max-width: 812px) {
        img {
          width: 65%;
        }
      }
    }
  }
  .scroll-image {
    @media screen and (max-width: 768px) {
      display: none;
  }
  }
}

@keyframes loading {
  0%{
    left: -45%;
  }
  100%{
    left: 100%;
  }
}