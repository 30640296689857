.governance {
  .governance-wrapper {
    margin-top: 100px;
    position: relative;
    .governance-content {
      padding: 120px 100px 100px 100px;
      @media screen and (max-width: 812px) {
        padding-left: 20px;
        padding-right: 20px;
      }
      .title {
        font-size: 20px;
        padding: 5px;
        font-weight: 800;
        background: #1e1a22;
        border-radius: 5px;
        display: inline-block;
        line-height: 27px;
        margin-bottom: 20px;
      }
      .desc {
        color: var(--color-text-main);
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        line-height: 40px;
        max-width: 920px;
        margin: 0 auto 60px auto;

        @media screen and (max-width: 812px) {
          font-size: 20px;
        }
        @media screen and (max-width: 1024px) {
          font-size: 22px !important;
        }
      }
      .recent-proposals {
        padding: 6px;
        max-width: 1200px;
        margin: 0 auto;
        background: linear-gradient(
          to bottom,
          #0146d0,
          #0146d0,
          rgba(255, 255, 255, 0.7),
          rgba(255, 255, 255, 0.5),
          rgba(30, 26, 34, 0.8),
          #1e1a22
        );

        .recent-proposals-inner {
          background: #1e1f25;
          padding: 20px 30px 52px 30px;
        }

        @media screen and (max-width: 812px) {
          padding-bottom: 52px;
        }
        // max-width: 680px;
        position: relative;
        z-index: 2;
        .pagination {
          text-align: right;
          margin-top: 20px;
          @media screen and (max-width: 812px) {
            margin-top: 42px;
          }
          .ant-pagination-item-link {
            background: none;

            .ant-pagination-item-container {
              border: none;
            }

            i.anticon.anticon-left {
              color: var(--color-text-main) !important;
            }
            i.anticon.anticon-right {
              color: var(--color-text-main) !important;
            }
            .ant-pagination-item-ellipsis {
              color: var(--color-text-main) !important;
            }
          }
          .ant-pagination-item a {
            color: #666;
          }
          .ant-pagination-item {
            background: none !important;
          }
          .ant-pagination-item:focus a,
          .ant-pagination-item:hover a {
            color: var(--color-text-main);
          }

          .ant-pagination-item-active {
            a {
              color: var(--color-text-main);
            }
          }
        }
        .title-proposals {
          color: #ffffff;
          font-weight: 800;
          font-size: 20px;
          padding-bottom: 10px;
          border-bottom: 1px solid #34384c;
          @media screen and (max-width: 812px) {
            font-size: 16px;
          }
        }
        .recent-list {
          .recent-item {
            padding: 10px 0;
            border-bottom: 1px solid #34384c;
            cursor: pointer;
            .description {
              color: #ffffff;
              font-size: 20px;
              @media screen and (max-width: 812px) {
                font-size: 16px;
              }
            }
            .status {
              background: #06c270;
              border-radius: 5px;
              color: #ffffff;
              font-weight: 800;
              padding: 2px 10px;
              @media screen and (max-width: 812px) {
                display: inline-block;
                font-size: 12px !important;
              }
            }
            .failed {
              background: #ee3636;
              border-radius: 5px;
              color: #ffffff;
              font-weight: 800;
              padding: 2px 10px;
              @media screen and (max-width: 812px) {
                display: inline-block;
                font-size: 12px !important;
              }
            }
            .time {
              color: #9d9fa7;
              font-size: 14px;
            }
            .state {
              color: #ffffff;
              font-size: 14px;
              @media screen and (max-width: 812px) {
                font-size: 12px !important;
              }
            }
          }
        }
        .arrow-cross {
          position: absolute;
          bottom: -60px;
          left: 50px;
          @media screen and (max-width: 812px) {
            bottom: -10px;
            img {
              width: 35px;
              left: 30px;
            }
          }
        }
        .arrow-cross-black-opacity {
          position: absolute;
          top: -100px;
          right: 50px;
        }
        .arrow-cross-black {
          position: absolute;
          bottom: 0;
          right: -60px;
          z-index: 1;
          opacity: 0.5;
          @media screen and (max-width: 812px) {
            display: none;
          }
        }
      }
    }
  }
}
