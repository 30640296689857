.footer {
  position: relative;
  padding: 150px 90px 10px 90px;
  background: linear-gradient(180deg, #1e1a2200 0%, #1e1a22 7%, #1e1a22 14%, #1e1a2200 21%);

  @media screen and (max-width: 1024px) {
    padding: 150px 30px 30px 30px;
  }

  .footer-content {
    color: #39496a;
    @media screen and (max-width: 1024px) {
      display: block;
    }
    .footer-options {
      width: 100%;
      font-weight: 500;
      font-size: 20px;
      padding: 0px 30px;

      @media screen and (max-width: 768px) {
        padding: 0px 30px;
      }

      @media screen and (max-width: 540px) {
        padding: 0px 10px;
      }

      .desktop-menu {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr;
      }

      .title {
        margin-bottom: 20px;
        font-size: 20px;
        color: var(--color-text-main);
      }

      .description {
        max-width: 240px;
        color: #AAA;
        font-size: 14px;
        font-weight: 400;
        line-height: 27px
      }

      .about-title {
        margin-bottom: 20px;
        font-size: 20px;
        color: var(--color-text-main);
        text-align: center;
      }

      .about-description {
        max-width: 322px;
        color: #AAA;
        font-size: 14px;
        font-weight: 400;
        line-height: 27px;
        text-align: center;
        margin: 0px auto 50px;
      }

      .options {
        .option {
          margin-bottom: 20px;
          white-space: nowrap;

          a {
            color: #aaaaaa;
            font-size: 16px;
          }
          div {
            color: #aaaaaa;
            cursor: pointer;
            font-size: 16px;
          }
        }
      }
      .app-btn {
        width: 150px;
        height: 50px;
        border-radius: 5px;
        box-shadow: 0px 4px 13px 0 rgba(39, 126, 230, 0.64);
        background-color: #277ee6;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
        &:hover {
          background-color: #477ee6;
          color: #ffffff;
        }
      }
    }
  }
  .latest-block-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-top: 1px solid white;

    .copyright {
      font-size: 16px;
      font-weight: 400;
      color: var(--color-text-main);
    }

    @media screen and (max-width: 768px) {
      img {
        width: 90px;
      }

      .copyright {
        font-size: 12px;
      }
    }

    @media screen and (max-width: 540px) {
      padding: 20px 10px;
    }
  }
}
